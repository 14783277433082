import React, { useEffect } from 'react';

const NutribotLivingComponent = () => {
    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = 'https://app.ecommerce.ionos.de/script.js?111837043&data_platform=code&data_date=2025-01-22';
        script1.type = 'text/javascript';
        script1.charset = 'utf-8';
        script1.setAttribute('data-cfasync', 'false');
    
        // Handle the script loading success
        script1.onload = () => {
          try {
            // Safely execute xProductBrowser function
            if (window.xProductBrowser) {
              window.xProductBrowser(
                "categoriesPerRow=3",
                "views=grid(20,1) list(60) table(60)",
                "categoryView=grid",
                "searchView=list",
                "id=my-store-111837043"
              );
            }
          } catch (error) {
            // Simply ignore the error or handle it silently
          }
        };
    
        // Handle any errors when loading the external script
        script1.onerror = () => {
          // Optionally handle script load failure without logging it
        };
    
        // Append the script to the body of the document
        document.body.appendChild(script1);
    
        // Cleanup: remove the script when the component is unmounted
        return () => {
          document.body.removeChild(script1);
        };
      }, []);

  return (
    <div>
      {/* Element where the store will be rendered */}
      <div id="my-store-111837043"></div>
    </div>
  );
};

export default NutribotLivingComponent;

