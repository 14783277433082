import "./nutribotliving.css"
const NutribotLiving = () => { 
     


   
     return (
          <div className="home">
               <div className="intro2">
               
                    <div className="teaser">
                         One simple step to success
                         <br></br>
                         
                         <img src="/img/Feeder-ultimate-Grafik.png" className="cover5" alt="nutribot"></img>
                         <br></br>
                         Get fresh Water 
                         <br></br> 
                         <div className="teaser2" >
                              The most important thing,               
                              growing on living soil,                
                              is to keep the moisture of the soil    
                                   
                         </div>

                         

                    </div>
               </div>
               
          </div>
     )
}

export default NutribotLiving
