import "./nutribot.css"

function Nutribot() {
     return (
          <div className="home">
               <div className="intro2">
               
                    <div className="teaser">
                         Three simple steps to success
                         <br></br>
                         
                         <img src="/img/nutribot2.png" className="cover2" alt="nutribot"></img>
                         <br></br>
                         Get fresh Water, Get some Nutrients, Feed'em 
                         <br></br> 
                         <div className="teaser2" >
                              The full Nutribot system, feeds whatever you want  
                                   
                         </div>

                         




                         <div>
                         
                         
                        
                    
                          
                          
                          
                        
                         </div>
                         
                    </div>
               </div>
               
          </div>
     )
}

export default Nutribot
