import React from 'react';
import "./impressum.css"
const Impressum = () => {

return (
    
    <div class="impressum-container">
    <h4>Impressum</h4>
    <p><b>Informationen und Offenlegung gemäß &sect;5 (1) ECG, &sect; 25 MedienG, &sect; 63 GewO und &sect; 14 UGB</b></p>
    <p><b>Webseitenbetreiber:</b> Philipp Koneczny</p>
    <p><b>Anschrift:</b> Blumenweg 4, 5400 Hallein</p>
    <p><b>UID-Nr:</b> to be added <br/> <b>Gewerbeaufsichtbehörde:</b> Bezirkshauptmannschaft Hallein <br/> <b>Mitgliedschaften:</b>Mitglied der WKO</p>
    <p><b>Kontaktdaten:</b> <br/> Telefon: 06603236301 <br/> Email: office@feeder.at <br/> Fax: </p>

    <p><b>Anwendbare Rechtsvorschrift:</b> www.ris.bka.gv.at <br/> <b>Berufsbezeichnung:</b> Gewerbe</p>
    <div class="streitbeilegung">
        <p><b>Online Streitbeilegung:</b> Verbraucher, welche in Österreich oder in einem sonstigen Vertragsstaat der ODR-VO niedergelassen sind, haben die Möglichkeit Probleme bezüglich dem entgeltlichen Kauf von Waren oder Dienstleistungen im Rahmen einer Online-Streitbeilegung (nach OS, AStG) zu lösen. Die Europäische Kommission stellt eine Plattform hierfür bereit: <a href="https://ec.europa.eu/consumers/odr" target="_blank">Link zur Plattform</a></p>
    </div>
    <p><b>Urheberrecht:</b> Die Inhalte dieser Webseite unterliegen, soweit dies rechtlich möglich ist, diversen Schutzrechten (z.B dem Urheberrecht). Jegliche Verwendung/Verbreitung von bereitgestelltem Material, welche urheberrechtlich untersagt ist, bedarf schriftlicher Zustimmung des Webseitenbetreibers.</p>
    <p><b>Haftungsausschluss:</b> Trotz sorgfältiger inhaltlicher Kontrolle übernimmt der Webseitenbetreiber dieser Webseite keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Sollten Sie dennoch auf ausgehende Links aufmerksam werden, welche auf eine Webseite mit rechtswidriger Tätigkeit/Information verweisen, ersuchen wir um dementsprechenden Hinweis, um diese nach § 17 Abs. 2 ECG umgehend zu entfernen.<br/>Die Urheberrechte Dritter werden vom Betreiber dieser Webseite mit größter Sorgfalt beachtet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden derartiger Rechtsverletzungen werden wir den betroffenen Inhalt umgehend entfernen.</p>
    <p>Quelle: <b><a href="https://www.fairesrecht.at/kostenlos-impressum-erstellen-generator.php" target="_blank">Impressum Generator Österreich</a></b></p>
</div>

    
  );
};

export default Impressum;
