import "./footer.css"
import { Link } from "react-router-dom"
function Footer() {
     return (
          <div className="footer">
               <div className="first2">
                    <div className="col1">
                         <div className="headlinefoot row11">Nutribot</div>
                         <div className="row22 borderfoot"></div>
                         <Link className="nolineee" to="/botl">
                         <div className="row33">Produkt</div>
                         </Link>
                         <Link className="nolineee" to="/bot">
                         <div className="row44">System mit zubehör</div>
                         </Link>
                         <Link className="nolineee" to="/tut">
                         <div className="row55">Bedienungsanleitung</div>
                         </Link>
                    </div>

                    <div className="col2">
                         <div className="headlinefoot row11">INFO</div>
                         <div className="row22 borderfoot"></div>
                         <Link className="nolineee" to="/about">
                         <div className="row33">Über Mich</div>
                         </Link>
                         <div className="row44"></div>
                         <div className="row55"></div>
                    </div>

                    <div className="col3">
                         <div className="headlinefoot row11">HILFEBEREICH</div>
                         <div className="row22 borderfoot"></div>
                         <div className="row33">Versandinfo</div>
                         <div className="row44">Zahlungsart</div>
                         <div className="row55">Kontakt</div>
                    </div>
               </div>
               <div className="secondfoot">
                    <div className="borderline"></div>
                    <div className="col1">
                         <div className="headlinefoot row11">Folge mir</div>
                         <div className="row22 borderfoot"></div>
                         <div className="row44 nolineee" href="https://www.instagram.com/feeder_bot/">
                         <a className="social-icon nolineee" data-tooltip="Instagram" data-toggle="tooltip" data-placement="top" title="instagram" href="https://www.instagram.com/feeder_bot/">
                              <i className="nolineee fa fa-instagram" aria-hidden="true"></i>
                         </a>
                         </div>
                         <div className="row55"></div>
                    </div>

                    <div className="col2">
                         <div className="headlinefoot row11">NEWSLETTER</div>
                         <div className="row22 borderfoot"></div>
                         <div className="row33"></div>
                         <div className="row44"></div>
                         <div className="row55"></div>
                    </div>
               </div>
               <div className="thirdfoot">
                    <div className="borderline"></div>
                    <div className="col1">
                         <div className="row11 rights">© 2024 All rights Reserved. Design by Philipp Koneczny</div>
                    </div>

                    <div className="col23">
                         <div className="column2">AGB</div>
                         <Link className="nolineee" to="/impressum">
                         <div className="column3">Impressum</div>
                         </Link>
                         <Link className="nolineee" to="/dsvo">
                         <div className="column4">Datenschutz</div>
                         </Link>
                    </div>
               </div>
          </div>
     )
}

export default Footer
