import React from 'react';
import "./impressum.css"
const DSVO = () => {

return (
    
    <div class="datenschutz-container">
    <h3>Erklärung zur Informationspflicht</h3>
    <p align="center"><strong>Datenschutzerklärung</strong></p>
    <p>In folgender Datenschutzerklärung informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Webseite. Wir erheben und verarbeiten personenbezogene Daten nur auf Grundlage der gesetzlichen Bestimmungen (Datenschutzgrundverordnung, Telekommunikationsgesetz 2003).</p>
    <p>Sobald Sie als Benutzer auf unsere Webseite zugreifen oder diese besuchen, wird Ihre IP-Adresse, Beginn sowie Beginn und Ende der Sitzung erfasst. Dies ist technisch bedingt und stellt somit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO dar.</p>
    
    <h5>Kontakt mit uns</h5>
    <p>Wenn Sie uns, entweder über unser Kontaktformular auf unserer Webseite oder per Email kontaktieren, dann werden die von Ihnen an uns übermittelten Daten zwecks Bearbeitung Ihrer Anfrage oder für den Fall von weiteren Anschlussfragen für sechs Monate bei uns gespeichert. Es erfolgt, ohne Ihre Einwilligung, keine Weitergabe Ihrer übermittelten Daten.</p>
    
    <h5>Datenspeicherung</h5>
    <p>Im Rahmen der Erleichterung des Einkaufsvorganges und zur späteren Vertragsabwicklung werden vom Webshop-Betreiber im Rahmen von Cookies die IP-Adresse des Anschlussinhabers gespeichert, ebenso wie Name, Adresse, E-Mail, Kreditkartendaten.</p>
    <p>Für die Vertragsabwicklung werden auch folgende Daten bei uns gespeichert:<br/> Email.</p>
    <p>Die von Ihnen bereitgestellten Daten sind für die Vertragserfüllung und zur Durchführung vorvertraglicher Maßnahmen erforderlich. Ein Vertragsabschluss ohne diese Daten ist nicht möglich. Eine Übermittlung der erhobenen Daten an Dritte erfolgt nicht, mit Ausnahme der Übermittlung von Zahlungsdaten (Kreditkartendaten) an die abwickelnden Bankinstitute/Zahlungsdienstleister zum Zwecke der Abbuchung des Einkaufspreises, an das von uns beauftragte Versandunternehmen (Transportunternehmen), welches mit der Zustellung der Ware beauftragt ist und unseren Steuerberater zur Erfüllung unserer steuerrechtlichen Verpflichtungen.</p>
    <p>Sollten Sie den Einkaufsvorgang abbrechen, werden diese bei uns gespeicherten Daten gelöscht. Sollte ein Vertragsabschluss zustande kommen, werden sämtliche Daten, resultierend aus dem Vertragsverhältnis, bis zum Ablauf der steuerrechtlichen Aufbewahrungsfrist (7 Jahre) gespeichert. Der übermittelte Name, die Anschrift, gekaufte Waren und Kaufdatum werden darüber hinaus bis zum Ablauf der Produkthaftung (10 Jahre) gespeichert. Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des § 96 Abs 3 TKG sowie des Art 6 Abs 1 lit a (Einwilligung) und/oder lit b (notwendig zur Vertragserfüllung) der DSGVO.</p>

    <h5>Cookies</h5>
    <p>Unsere Website verwendet sogenannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an. Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen. Wenn Sie dies nicht wünschen, können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.</p>

    <h5>Newsletter</h5>
    <p>Sie haben die Möglichkeit, über unsere Website unseren Newsletter zu abonnieren. Hierfür benötigen wir Ihre E-Mail-Adresse und Ihre Erklärung, dass Sie mit dem Bezug des Newsletters einverstanden sind.</p>
    <p>Wenn Sie die Möglichkeit nutzen, unseren Newsletter zu abonnieren, wird Ihre E-Mail-Adresse und Ihre Zustimmung/Einverständniserklärung über den Bezug des Newsletters benötigt. In Folge auf die Anmeldung zu unserem Newsletter erhalten Sie von uns ein Bestätigungs-E-Mail mit einem Link zur Anmeldebestätigung. Eine Stornierung des Abos ist jederzeit möglich. Senden Sie die Stornierung bitte an unsere E-Mail-Adresse: office@feeder.at.</p>
    <p>Ihre Daten im Zusammenhang mit dem Newsletter-Versand werden im Falle einer Stornierung umgehend von uns gelöscht. Die Rechtmäßigkeit der erfolgten Datenverarbeitung bis zum Widerruf, welche aufgrund der Zustimmung passiert ist, wird nicht berührt.</p>

    <h5>Ihre Rechte als Betroffener</h5>
    <p>Sie als Betroffener haben bezüglich Ihrer Daten, welche bei uns gespeichert sind, grundsätzlich ein Recht auf:</p>
    <ul>
        <li>Auskunft</li>
        <li>Löschung der Daten</li>
        <li>Berichtigung der Daten</li>
        <li>Übertragbarkeit der Daten</li>
        <li>Widerruf und Widerspruch zur Datenverarbeitung</li>
        <li>Einschränkung</li>
    </ul>
    <p>Wenn Sie vermuten, dass im Zuge der Verarbeitung Ihrer Daten Verstöße gegen das Datenschutzrecht passiert sind, so haben Sie die Möglichkeit, sich bei uns (office@feeder.at) oder der Datenschutzbehörde zu beschweren.</p>

    <h5>Sie erreichen uns unter folgenden Kontaktdaten:</h5>
    <p><b>Webseitenbetreiber:</b> Philipp Koneczny<br/> <b>Telefonnummer:</b> 06603236301<br/> <b>Email:</b> office@feeder.at</p>

    <h5>Datenschutzbeauftragter</h5>
    <p>Unseren Datenschutzbeauftragten erreichen Sie unter: <br/> <b>Name:</b> Philipp Koneczny <br/> <b>Email:</b> office@feeder.at <br/><b>Telefon:</b> +436603236301</p>
    
    <p>Quelle: <b><a href="https://www.fairesrecht.at/kostenlos-datenschutzerklaerung-erstellen-generator.php" target="_blank">Datenschutzgenerator Österreich DSGVO</a></b></p>
</div>


    
  );
};

export default DSVO;
